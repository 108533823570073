import { MapPin } from '@phosphor-icons/react/dist/ssr/MapPin';
import { useMemo, type FC } from 'react';
import type { ApiGouvAutocompleteResult } from '../types';
import { cva, type VariantProps } from 'class-variance-authority';
import { StoreLocatorUtils } from '@web/utils/store-locator-utils';

type Props = VariantProps<typeof styles> & {
  result: ApiGouvAutocompleteResult;
};

const styles = cva(
  'mx-2 my-1 flex cursor-pointer items-center gap-4 rounded-lg p-2 ring-inset transition-colors',
  {
    variants: {
      active: {
        true: 'bg-brand-black/5 ring-2 ring-brand-black',
      },
      selected: {
        true: '!bg-brand-50 !ring-brand',
      },
    },
  },
);

const iconsStyles = cva(
  'aspect-square rounded-full p-1.5 transition-colors',
  {
    variants: {
      active: {
        true: 'bg-brand-black text-white',
      },
      selected: {
        true: '!bg-brand text-white',
      },
    },
    compoundVariants: [
      {
        active: false,
        selected: false,
        class: 'bg-light-grey'
      }
    ]
  },
)

export const StoreLocatorFormAutocompleteResult: FC<Props> = function ({
  active,
  selected,
  result,
}) {
  const {label, details} = useMemo(
    () => StoreLocatorUtils.formatApiGouvResult(result),
    [result],
  );

  return (
    <dl className={styles({ active, selected })}>
      <div className={iconsStyles({active, selected})}>
        <MapPin weight="bold" size={20} />
      </div>

      <div className="flex-1 overflow-hidden">
        <dt className="leading-tight">{label}</dt>
        <dd className="truncate font-mono text-sm text-gray-400">{details}</dd>
      </div>
    </dl>
  );
};
